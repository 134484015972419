import React, { useEffect, useRef, useState } from "react";
import "../../resources/templates.css";
import { Progress } from "antd";

const Template3 = (props) => {
  const backColor = props.colorBackStyle;
  const fontColor = props.colorFontStyle;

  const user = JSON.parse(localStorage.getItem("pohlresume-user"));
  const imageUrl = user.profileImg;
  const contentRef = useRef(null);
  const [minimumHeight,setMinimumHeight]= useState(contentRef.current?.style.minHeight)

  useEffect(() => {
    const content = contentRef.current;
    const pageHeight = 297 * 3.77953; // A4 height in millimeters converted to pixels at 96 DPI
    const totalHeight = content.scrollHeight;
    const numPages = Math.ceil(totalHeight / pageHeight); // Runden auf die nächste volle Seite
    const requiredHeight = numPages * pageHeight;
    console.log(numPages)

    content.style.minHeight = `${requiredHeight}px`;
    setMinimumHeight(requiredHeight)
  }, []);

  return (
    <div
      className="template1-parent container-fluid"
      ref={contentRef}
      style={{ backgroundColor: backColor, /* width:"210mm" */}}
    >
      <div className="row">
        {/* Linke Spalte */}
        <div
          className="col-4 column-left"
          style={{
            backgroundColor: backColor,
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: "10px",
            color: fontColor,
            minHeight: minimumHeight, // Setzt die Mindesthöhe auf die berechnete Höhe
          }}
        >
          <div>
            <div>
              {imageUrl && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      borderRadius: "50%",
                      width: "200px",
                      height: "200px",
                    }}
                  >
                    <img
                      src={imageUrl}
                      alt="Uploaded file"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
              )}
              <p style={{ fontSize: "14pt" }}>
                <b>Persönliche Daten</b>
              </p>
              <hr style={{ margin: "0px", padding: "0px", color: fontColor }} />
              <p style={{ fontSize: "9pt" }}>
                <b>Vorname Nachname:</b>
              </p>
              <p style={{ fontSize: "12pt" }}>
                {user.firstName} {user.lastName}
              </p>
              <p style={{ fontSize: "9pt" }}>
                <b>Geboren am / in:</b>
              </p>
              <p style={{ fontSize: "12pt" }}>
                {user.bornOn} / {user.bornIn}
              </p>
            </div>
            <div>
              <p style={{ fontSize: "9pt" }}>
                <b>E-mail:</b>
              </p>
              <p style={{ fontSize: "12pt" }}>{user.email}</p>

              <p style={{ fontSize: "9pt" }}>
                <b>Adresse:</b>
              </p>
              <p style={{ fontSize: "12pt" }}>{user.address}</p>
              <p style={{ fontSize: "12pt" }}>{user.plz}</p>
              <p style={{ fontSize: "12pt" }}>{user.ort}</p>

              <p style={{ fontSize: "9pt" }}>
                <b>Telefon:</b>
              </p>
              <p style={{ fontSize: "12pt" }}>{user.mobileNumber}</p>

              {user.portfolio && user.portfolio.length !== 0 && (
                <>
                  <p style={{ fontSize: "10pt" }}>
                    <b>Webseite:</b>
                  </p>
                  <p style={{ fontSize: "12pt" }}>{user.portfolio}</p>
                </>
              )}
              {user.linkedIn && user.portfolio.linkedIn !== 0 && (
                <>
                  <p style={{ fontSize: "10pt" }}>
                    <b>LinkedIn:</b>
                  </p>
                  <p style={{ fontSize: "12pt" }}>{user.linkedIn}</p>
                </>
              )}
              {user.instagram && user.portfolio.instagram !== 0 && (
                <>
                  <p style={{ fontSize: "10pt" }}>
                    <b>Instagram:</b>
                  </p>
                  <p style={{ fontSize: "12pt" }}>{user.instagram}</p>
                </>
              )}

              <br />
              <p style={{ fontSize: "14pt" }}>
                <b>Sprachen</b>
              </p>
              <hr style={{ margin: "0px", padding: "0px" }} />
              {user.languages && user.languages.length !== 0 && (
                <p style={{ fontSize: "12pt" }}>{user.languages}</p>
              )}
              <br />
              <p style={{ fontSize: "14pt" }}>
                <b>Hobbys und Interessen</b>
              </p>
              <hr style={{ margin: "0px", padding: "0px" }} />
              {user.hobbys && user.hobbys.length !== 0 && (
                <p style={{ fontSize: "12pt" }}>{user.hobbys}</p>
              )}
            </div>
          </div>
        </div>
        {/* Rechte Spalte */}
        <div
          className="col-8 column-right"
          style={{
            backgroundColor: "white",
            paddingTop: "20px",
            paddingBottom: "0px",
            minHeight:minimumHeight, // Setzt die Mindesthöhe auf die berechnete Höhe
          }}
        >
          <h1>
            {user.firstName.toUpperCase()} {user.lastName.toUpperCase()}
          </h1>
          {user.carrierObjective && user.carrierObjective.length !== 0 && (
            <div className="objective mt-3">
              <p style={{ fontSize: "12pt" }}>{user.carrierObjective}</p>
            </div>
          )}
          <div className="divider mt-2" />
          <div className="experience mt-2">
            <h3 style={{ fontSize: "16pt" }}>Beruflicher Werdegang</h3>

            {user.experience.map((exp) => {
              return (
                <>
                  <hr style={{ marginTop: 5, marginBottom: 5 }} />

                  <div className="d-flex align-items-center">
                    <h6 className="col-4" style={{ fontSize: "12pt" }}>
                      {exp.range && <b>{exp.range} : </b>}
                    </h6>
                    <p className="col-8" style={{ fontSize: "12pt" }}>
                      <b>{exp.company}</b> als <b>{exp.place}</b>
                    </p>
                  </div>
                </>
              );
            })}
          </div>
          <div className="divider mt-2" />

          <div className="education mt-2">
            <h3 style={{ fontSize: "16pt" }}>Schulische Bildung</h3>

            {user.education.map((education) => {
              return (
                <>
                  <hr style={{ marginTop: 6, marginBottom: 6 }} />
                  <div className="d-flex align-items-center">
                    <h6 className="col-4" style={{ fontSize: "12pt" }}>
                      <b>{education.range} </b>
                    </h6>
                    <p className="col-8" style={{ fontSize: "12pt" }}>
                      <b>{education.qualification}</b> in{" "}
                      {education.institution}
                    </p>
                  </div>
                </>
              );
            })}
          </div>

          <div className="divider mt-2" />
          <div className="projects mt-2">
            <h3 style={{ fontSize: "16pt" }}>Projekte</h3>

            {user.projects.map((project) => {
              return (
                <div className="d-flex flex-column">
                  <hr style={{ marginTop: 6, marginBottom: 6 }} />
                  <h6 style={{ fontSize: "12pt" }}>
                    <b>
                      {project.title}{" "}
                      {project.range ? `[${project.range}]` : null}
                    </b>
                  </h6>
                  <p style={{ fontSize: "12pt" }}>{project.rating}</p>
                </div>
              );
            })}
          </div>
          <div className="divider mt-2" />
          <div className="projects mt-2">
            <h3 style={{ fontSize: "16pt" }}>Fähigkeiten</h3>
            <hr />
            <div className="d-flex justify-content-center flex-wrap">
              {user.skills.map((skill) => {
                return (
                  <Progress
                    type="dashboard"
                    format={() => `${skill.technology}`}
                    strokeColor={backColor}
                    percent={skill.rating}
                    gapDegree={90}
                    style={{ margin: 10 }}
                    size={100}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template3;
