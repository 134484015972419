import React, { useEffect, useState } from "react";
import "../../resources/templates.css";
import { Progress } from "antd";
function Template2(props) {
  const user = JSON.parse(localStorage.getItem("pohlresume-user"));
  const [imageUrl, setImageUrl] = useState(null);
  const backColor = props.colorBackStyle;
  const fontColor = props.colorFontStyle;

  useEffect(() => {
    const fetchFirstFile = async () => {
      try {
        const response = await fetch(`/api/user/firstfile/${user._id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch first file");
        }
        const data = await response.json();
        setImageUrl(data.imageUrl);
      } catch (error) {
        console.error("Error fetching first file:", error);
      }
    };
    fetchFirstFile();
  }, [user._id]);

  return (
    <div className="template1-parent mx-3">
      <div className="top d-flex justify-content-between align-items-center">
      <div className="top d-flex flex-column">
        <h1>
          {user.firstName.toUpperCase()} {user.lastName.toUpperCase()}
        </h1>
        <div>
          <p>{user.email}</p>
          <p>{user.address}</p>
          <p>{user.mobileNumber}</p>
        </div>
      </div>
      <div>
      {imageUrl && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "20px",
                  }}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      borderRadius: "30%",
                      width: "150px",
                      height: "150px",
                      
                    }}
                  >
                    <img
                      src={imageUrl}
                      alt="Uploaded file"
                      style={{
                        height:"100%",
                        width:"100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
              )}
      </div>
      </div>

      <div className="divider mt-3"></div>
      <div className="divider mt-1"></div>

      {user.carrierObjective && user.carrierObjective.length !== 0 && (
        <div className="objective mt-3">
          <p>{user.carrierObjective}</p>
        </div>
      )}
      {user.carrierObjective && user.carrierObjective.length !== 0 && (
        <div className="divider mt-3" />
      )}

      <div className="education mt-3">
        <h3
          style={{ backgroundColor: backColor, color: fontColor, padding: 10 }}
        >
          Schulische Bildung
        </h3>
        <hr />
        {user.education.map((education) => {
          return (
            <div className="d-flex align-items-center">
              <h6 style={{ width: 120 }}>
                <b>{education.range} : </b>
              </h6>
              <p>
                <b>{education.qualification}</b> in {education.institution}
              </p>
            </div>
          );
        })}
      </div>

      <div className="divider mt-3"></div>

      <div className="experience mt-3">
        <h3
          style={{ backgroundColor: backColor, color: fontColor, padding: 10 }}
        >
          Beruflicher werdegang
        </h3>
        <hr />
        {user.experience.map((exp) => {
          return (
            <div className="d-flex align-items-center">
              <h6 style={{ width: 120 }}>
                <b>{exp.range} : </b>
              </h6>
              <p>
                <b>{exp.company}</b> als <b>{exp.place}</b>
              </p>
            </div>
          );
        })}
      </div>

      <div className="divider mt-3"></div>

      <div className="projects mt-3">
        <h3
          style={{ backgroundColor: backColor, color: fontColor, padding: 10 }}
        >
          Projekte
        </h3>
        <hr />
        {user.projects.map((project) => {
          return (
            <div className="d-flex flex-column">
              <h6>
                <b>
                  {project.title} {project.range ? `[${project.range}]` : null}
                </b>
              </h6>
              <p>{project.rating}</p>
            </div>
          );
        })}
      </div>

      <div className="divider mt-3"></div>

      <div className="projects mt-2">
      <h3
          style={{ backgroundColor: backColor, color: fontColor, padding: 10 }}
        >
          Fähigkeiten
        </h3>
        

            {user.skills.map((skill) => {
              return (
                <>
                  <hr style={{ marginTop: 3, marginBottom: 3 }} />

                  <p className="col-sm-12" >
                    {skill.rating && (
                      <div className="d-flex align-items-center">
                        <p className="col-sm-2" >
                          {skill.technology}
                        </p>
                        <Progress
                          className="col-sm-10"
                          percent={skill.rating}
                          showInfo={false}
                          strokeColor={backColor}
                          
                        />
                      </div>
                    )}
                  </p>
                </>
              );
            })}
          </div>
    </div>
  );
}

export default Template2;
