import React, { useEffect, useState } from "react";
import "../../resources/templates.css";
function Template1(props) {
  const [imageUrl, setImageUrl] = useState(null);
  const backColor = props.colorBackStyle;
  const fontColor = props.colorFontStyle;
  const user = JSON.parse(localStorage.getItem("pohlresume-user"));


  useEffect(() => {
    const fetchFirstFile = async () => {
      try {
        const response = await fetch(`/api/user/firstfile/${user._id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch first file");
        }
        const data = await response.json();
        setImageUrl(data.imageUrl);
      } catch (error) {
        console.error("Error fetching first file:", error);
      }
    };
    fetchFirstFile();
  }, [user._id]);

  return (
    <div className="template1-parent mx-3">
      <div className="top d-flex justify-content-between">
        <div >
        
        <h1>
          {user.firstName.toUpperCase()} {user.lastName.toUpperCase()}
        </h1>

        
        </div>
        <div>
          <p>{user.email}</p>
          <p>{user.address}</p>
          <p>{user.mobileNumber}</p>

          {user.portfolio && user.portfolio.length !== 0 && (
            <p>{user.portfolio}</p>
          )}
        </div>
      </div>

      {user.carrierObjective && user.carrierObjective.length !== 0 && (
        <div className="divider mt-3" />
      )}

      {user.carrierObjective && user.carrierObjective.length !== 0 && (
        <div className="objective mt-3">
          <p>{user.carrierObjective}</p>
        </div>
      )}
      <div className="divider mt-3"></div>
      <div className="education mt-3">
        <h3>Schulische Bildung</h3>
        <hr />
        {user.education.map((education) => {
          return (
            <div className="d-flex align-items-center">
              <h6 style={{ width: 120 }}>
                <b>{education.range} : </b>
              </h6>
              <p>
                <b>{education.qualification}</b> in {education.institution}
              </p>
            </div>
          );
        })}
      </div>

      <div className="divider mt-3"></div>

      <div className="experience mt-3">
        <h3>Beruflicher werdegang</h3>
        <hr />
        {user.experience.map((exp) => {
          return (
            <div className="d-flex align-items-center">
              <h6 style={{ width: 120 }}>
              {exp.range&&  <b>{exp.range} : </b>}
               
              </h6>
              <p>
                <b>{exp.company}</b> als <b>{exp.place}</b>
              </p>
            </div>
          );
        })}
      </div>

      <div className="divider mt-3"></div>

      <div className="projects mt-3">
        <h3>Projekte</h3>
        <hr />
        {user.projects.map((project) => {
          return (
            <div className="d-flex flex-column">
              <h6>
                <b>
                  {project.title} {project.range? `[${project.range}]` :null}
                </b>
              </h6>
              <p>{project.rating}</p>
            </div>
          );
        })}
      </div>

      <div className="divider mt-3"></div>

      <div className="projects mt-3">
        <h3>Fähigkeiten</h3>
        <hr />
        {user.skills.map((skill) => {
          return (
            <p>
              {skill.technology} : {skill.rating} <hr />
            </p>
          );
        })}
      </div>
    </div>
  );
}

export default Template1;
