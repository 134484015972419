import { Form, Input } from "antd";
import React from "react";

const { TextArea } = Input;

function PersonalInfo() {
  return (
    <div>
      <div className="row">
        <div className="col-md-4">
          <Form.Item
            name="firstName"
            label="Vorname"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-md-4">
          <Form.Item
            name="lastName"
            label="Nachname"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-md-4">
          <Form.Item
            name="bornOn"
            label="Geburts Datum"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-md-4">
          <Form.Item
            name="bornIn"
            label="Geburts Ort"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-md-4">
          <Form.Item name="email" label="Email" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </div>
        <div className="col-md-4">
          <Form.Item
            name="mobileNumber"
            label="Mobile Nummer"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-md-4">
          <Form.Item
            name="portfolio"
            label="Portfolio"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-md-4">
          <Form.Item
            name="linkedIn"
            label="LinkedIn"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-md-4">
          <Form.Item
            name="instagram"
            label="Instagram"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </div>
       

        <div className="col-md-12">
          <Form.Item
            name="carrierObjective"
            label="Karriereziele"
            rules={[{ required: false }]}
          >
            <TextArea />
          </Form.Item>
        </div>
        <div className="col-md-12">
          <Form.Item
            name="address"
            label="Addresse"
            rules={[{ required: false }]}
          >
            <Input />
            </Form.Item>
        </div>
        <div className="col-md-12">
          <Form.Item
            name="plz"
            label="PLZ"
            rules={[{ required: false }]}
          >
            <Input />
            </Form.Item>
        </div>
        <div className="col-md-12">
          <Form.Item
            name="ort"
            label="Ort"
            rules={[{ required: false }]}
          >
            <Input />
            </Form.Item>
        </div>
        <div className="col-md-12">
          <Form.Item
            name="languages"
            label="Sprachen"
            rules={[{ required: false }]}
          >
            <TextArea />
          </Form.Item>
        </div>
        <div className="col-md-12">
          <Form.Item
            name="hobbys"
            label="Hobbys und Interessen"
            rules={[{ required: false }]}
          >
            <TextArea />
          </Form.Item>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;
