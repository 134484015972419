import React, { useMemo, useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../../components/DefaultLayout";
import Template1 from "./Template1";
import Template2 from "./Template2";
import { Button, ColorPicker } from "antd";
import Template3 from "./Template3";

function Templates() {
  const [colorBackRgb, setcolorBackRgb] = useState("rgb(76, 84, 88)");
  const [colorFontRgb, setcolorFontRgb] = useState("rgb(255, 255, 255)");
  const [formatRgb, setFormatRgb] = useState("rgb");
  const [scale, setScale] = useState(1); // State for scale
  const [scaledWidth, setScaledWidth] = useState("210mm"); // State for scaled width
  const [scaledHeight, setScaledHeight] = useState("auto"); // State for scaled height

  const componentRef = useRef();
  const params = useParams();
  const navigate = useNavigate();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const rgbBackString = useMemo(
    () =>
      typeof colorBackRgb === "string"
        ? colorBackRgb
        : colorBackRgb?.toRgbString(),
    [colorBackRgb]
  );
  const rgbFontString = useMemo(
    () =>
      typeof colorFontRgb === "string"
        ? colorFontRgb
        : colorFontRgb?.toRgbString(),
    [colorFontRgb]
  );

  const gettemplate = () => {
    switch (params.id) {
      case "1":
        return (
          <Template1
            colorBackStyle={rgbBackString}
            colorFontStyle={rgbFontString}
          />
        );
      case "2":
        return (
          <Template2
            colorBackStyle={rgbBackString}
            colorFontStyle={rgbFontString}
          />
        );
      case "3":
        return (
          <Template3
            colorBackStyle={rgbBackString}
            colorFontStyle={rgbFontString}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    // Calculate the scale based on the viewport width and margins
    const updateScale = () => {
      const element = componentRef.current;
      if (element) {
        const paddingLeft = window.innerWidth < "769px" ? 25 : 70;
        const paddingRight = window.innerWidth < "769px" ? 25 : 70;
        const totalPadding = paddingLeft + paddingRight;

        const contentWidth = element.offsetWidth + totalPadding; // Width of the content + horizontal margins
        const viewportWidth = window.innerWidth; // Width of the viewport
        const newScale = viewportWidth / contentWidth; // Calculate scale factor

        setScale(newScale < 1 ? newScale : 1); // Set the scale, ensuring it's not larger than 1

        // Calculate and set new scaled dimensions
        const newScaledWidth = `${element.offsetWidth * newScale}px`;
        const newScaledHeight = `${element.offsetHeight * newScale}px`;
        setScaledWidth(newScaledWidth);
        setScaledHeight(newScaledHeight);
      }
    };

    updateScale(); // Initial scale calculation

    window.addEventListener("resize", updateScale); // Update scale on window resize

    return () => window.removeEventListener("resize", updateScale);
  }, []);

  return (
    <DefaultLayout>
      <div className="row my-2">
        <div className="d-flex justify-content-space-evenly col-sm-6 my-2">
          <div className="d-flex justify-items-center align-items-center">
            <span className="my-1 mx-1">Hintergrundfarbe: </span>
            <ColorPicker
              className="my-1 mx-1"
              format={formatRgb}
              value={colorBackRgb}
              onChange={setcolorBackRgb}
              onFormatChange={setFormatRgb}
            />
          </div>
          <div className="d-flex px-1 justify-content-center align-items-center">
            <span className="my-1 mx-1">Schriftfarbe: </span>
            <ColorPicker
              className="my-1 mx-1"
              format={formatRgb}
              value={colorFontRgb}
              onChange={setcolorFontRgb}
              onFormatChange={setFormatRgb}
            />
          </div>
        </div>

        <div className="d-flex justify-content-end col-sm-6">
          <Button className="back-btn my-1 mx-1" onClick={() => navigate("/home")}>
            Zurück
          </Button>
          <Button className="my-1 mx-1" onClick={handlePrint}>
            Drucken
          </Button>
        </div>
      </div>

      <div style={{ overflow: "hidden" }}>
        <div
          style={{
            margin: "auto",
            borderWidth: "1px",
            borderStyle: "dashed",
            borderColor: "black",
            width: "210mm", // Use the scaled width
            height: scaledHeight, // Use the scaled height
            transform: `scale(${scale})`,
            transformOrigin: "0 0", // Ensure scaling starts from the top-left corner
          }}
        >
          <div ref={componentRef}>{gettemplate()}</div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default Templates;

