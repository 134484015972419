import React, { useState } from "react";
import { Button, Form, message, Spin, Tabs } from "antd";
import PersonalInfo from "../components/PersonalInfo";
import SkillsEducation from "../components/SkillsEducation";
import ExperienceProjects from "../components/ExperienceProjects";
import FileUpload from "../components/FileUpload";
import DefaultLayout from "../components/DefaultLayout";

const { TabPane } = Tabs;

function Profile() {
  const [loading, setLoading] = useState(false);
  const [parentPostFile, setParentPostFile] = useState("");

  const user = JSON.parse(localStorage.getItem("pohlresume-user")) || {};

  const userImgB64=user.profileImg

   const handleParentFileUpload = (file) => {
    setParentPostFile(file);
    // Hier kannst du den Wert postFile in der Elternkomponente verwenden oder weiterverarbeiten
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await fetch("/api/user/update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...values,
          _id: user._id,
          profileImg:parentPostFile
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      localStorage.setItem("pohlresume-user", JSON.stringify(result));
      setLoading(false);
      message.success("Profile updated successfully");
    } catch (error) {
      setLoading(false);
      console.error("Error updating profile:", error);
      message.error(error.message || "An error occurred while updating the profile");
    }
  };

  
  return (
    <DefaultLayout>
      {loading && <Spin size="large" />}
      <div className="update-profile">
        <h4>
          <b>Profil</b>
        </h4>
        <hr />
        <Form layout="vertical" onFinish={onFinish} initialValues={user}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Persönliche Angaben" key="1">
            <FileUpload onFileUpload={handleParentFileUpload} userImg={userImgB64}/>
              <PersonalInfo />
            </TabPane>
            <TabPane tab="Foto hochladen" key="2">
              <FileUpload onFileUpload={handleParentFileUpload} userImg={userImgB64}/>
            </TabPane>
            <TabPane tab="Fähigkeiten and Bildung" key="3">
              <SkillsEducation />
            </TabPane>
            <TabPane tab="Erfahrungen und Projekte" key="4">
              <ExperienceProjects />
            </TabPane>
          </Tabs>
          <Button htmlType="submit">UPDATE</Button>
        </Form>
      </div>
    </DefaultLayout>
  );
}

export default Profile;
