//import logo from "./logo.svg";
import "./App.css";
import "antd";
import { Alert, Button } from "antd";
import {BrowserRouter,Routes, Route, Navigate} from 'react-router-dom'
import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import Templates from "./pages/templates";
import Profile from "./pages/Profile";



const App=()=> {


  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path="/templates/:id" element={<ProtectedRoute><Templates /></ProtectedRoute>} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;


export const ProtectedRoute=(props)=> {
  if (localStorage.getItem("pohlresume-user")) {
    return props.children;
  } else {
    return <Navigate to="/login" />;
  }
}