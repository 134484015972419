import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Form, Input,Button } from "antd";
import defimg from "./DefoultImage";
const { TextArea } = Input;

const FileUpload = ({ onFileUpload, userImg }) => {
  const [postFile, setPostFile] = useState("");

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const fileSize = file.size; // Dateigröße in Bytes
    const maxSizeInBytes = 75 * 1024; // Maximale Dateigröße in Bytes (hier: 10 MB)

    if (fileSize > maxSizeInBytes) {
      // Wenn die Dateigröße die maximale Größe überschreitet
      alert(
        "Die Datei ist zu groß. Bitte wählen Sie eine kleinere Datei. Maximal 75KB"
      );
      return;
    }

    // Wenn die Dateigröße innerhalb des Limits liegt, fahre fort mit der Verarbeitung
    const base64 = await convertToBase64(file);
    setPostFile(base64);
    onFileUpload(base64);
    console.log(base64);
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      <div className="row">
     
      <div className="col-md-3">
      
        <Input
          type="file"
          accept=".jpg, .png, .jpg"
          id="file-upload"
          onChange={(e) => handleFileUpload(e)}
         
        />
      
      </div>
        
      
      </div>
      <div className="col-md-3">
        <Button style={{width:"100%", height:"100%"}}>
        {postFile.length > 0 ? (
          <img
            src={postFile}
            alt=""
            style={{ marginTop: "10px", maxWidth: "100%" }}
          />
        ) : userImg.length > 0 ? (
          <img
            src={userImg}
            alt=""
            style={{ marginTop: "10px", maxWidth: "100%" }}
          />
        ) : (
          <img
            src={defimg}
            alt={""}
            style={{ marginTop: "10px", maxWidth: "100%" }}
          />
        )}
        </Button>
      </div>

    </div>
  );
};

export default FileUpload;

const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
