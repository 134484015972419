import React from "react";
import DefaultLayout from "../components/DefaultLayout";
import templateimg from "../resources/templates/template1.png";
import template2img from "../resources/templates/template2.png";
import template3img from "../resources/templates/template3.png";
import "../resources/templates.css";
import { useNavigate } from "react-router-dom";
const Home=()=> {
  const navigate = useNavigate();
  const templates = [
    {
      title: "Simple Resume",
      image: templateimg,
    },
    {
      title: "Highlighted Sections Resume",
      image: template2img,
    },
    {
      title: "Separate sections with image",
      image: template3img,
    },
  ];
  return (
    <DefaultLayout>
      <div className="row home">
        {templates.map((template, index) => {
          return (
            <div className="col-md-6 col-xl-4" key={index}>
              <div className="template" >
                <img
                  src={template.image}
                  
                  alt=""
                  style={{ maxWidth: "100%", height:"auto" }}
                />
                <div className="text" >
                  <p>{template.title}</p>
                  <button onClick={() => navigate(`/templates/${index + 1}`)}>
                    ansehen
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </DefaultLayout>
  );
}

export default Home;
