import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, message, Spin } from "antd";
import "../resources/authentication.css";
import { Link, useNavigate } from "react-router-dom";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const onFinish = async (values) => {
    setLoading(true);
    try {
      await fetch("/api/user/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      setLoading(false);
      message.success("Registration successfull");
    } catch (error) {
      setLoading(false);
      message.error("Registration failed");
    }
  };
  useEffect(() => {
    if (localStorage.getItem("pohlresume-user")) {
      navigate("/home");
    }
  });
  return (
    <div className="auth-parent">
      <h1 className="brand">Pohl CVM</h1>
      {loading && <Spin size="large" />}
      <Form layout="vertical" onFinish={onFinish}>
        <h1>Registrieren</h1>
        <hr />
        <Form.Item name="username" label="Benutzername">
          <Input />
        </Form.Item>
        <Form.Item name="password" label="Passwort">
          <Input type="password" />
        </Form.Item>

        <Form.Item name="cpassword" label="Passwort wiederholen">
          <Input type="password" />
        </Form.Item>
        <div className="d-flex align-items-center justify-content-between">
          <Link to="/login">hier gehts zum Login</Link>
          <Button type="primary" htmlType="submit">
          Registrieren
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default Register;
