import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, message, Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";
import "../resources/authentication.css";

const Register=()=> {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const onFinish = async (values) => {
    setLoading(true)
    try {
      const response = await fetch("/api/user/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      const data = await response.json();
      
      if (response.ok) {
        message.success("Login successful");
        localStorage.setItem("pohlresume-user", JSON.stringify(data));
        setLoading(false);
        navigate('/home');
      } else {
        message.error("Login failed");
        setLoading(false);
      }
    } catch (error) {
      message.error("Login failed");
      setLoading(false);
    }
  };

  useEffect(()=>{
    if(localStorage.getItem('pohlresume-user'))
    {
      navigate('/home')
    }
  })

  return (
    <div className="auth-parent">
      {loading && <Spin size="large"/>}
      <h1 className="brand">Pohl CVM</h1>
      <Form layout="vertical" onFinish={onFinish}>
        <h1>Login</h1>
        <hr />
        <Form.Item name="username" label="Benutzername">
          <Input />
        </Form.Item>
        <Form.Item name="password" label="Passwort">
          <Input type="password" />
        </Form.Item>

        <div className="d-flex align-items-center justify-content-between">
          <Link to="/register">hier gehts zum Regestrieren</Link>
          <Button type="primary" htmlType="submit">
            LOGIN
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default Register;
