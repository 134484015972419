import { Button, Dropdown, Menu } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./../resources/defaultlayout.css";
import { UserOutlined } from "@ant-design/icons";

function DefaultLayout(props) {
  const userJSON = localStorage.getItem("pohlresume-user");
  let user = null;
  try {
    user = userJSON ? JSON.parse(userJSON) : null;
  } catch (error) {
    console.error("Error parsing user JSON:", error);
    console.log("Invalid JSON in localStorage:", userJSON);
  }
  const navigate = useNavigate();

  const item = (
    <Menu>
      <Menu.Item key="home">
        <Link to="/home">Home</Link>
      </Menu.Item>
      <Menu.Item  key="profile" >
        <Link to="/profile">Profil</Link>
      </Menu.Item>
      <Menu.Item  key="logout"
        onClick={() => {
          localStorage.removeItem("pohlresume-user");
          navigate("/login");
        }}
      >
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="layout">
      <div className="header">
        <h1 onClick={() => navigate("/home")} style={{ cursor: "pointer" }}>
          Pohl CVM
        </h1>
        {user && user.username && (
         <Dropdown overlay={item} placement="bottomLeft" >
         <Button icon={<UserOutlined />}>{user.username}</Button>
       </Dropdown>
        )}
      </div>
      <div className="content" style={{ overflow: "scroll" }}>
        {props.children}
      </div>
    </div>
  );
}

export default DefaultLayout;
